import $ from 'jquery';
var myInterval;
var adminInterval;
export function check_add_admin(t) {

    var parameters = {
        room_guid: t.state.room_id,
        user_first_last_name: t.state.user_flname,
    };

    $.ajax({
        url: '/api/dvmeet/check_add_admin/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        async: true,
        contentType: 'application/json',
        dataType: "json",
        success: function (response) {
            console.log(response);

            if (response !== null && response !== undefined) {

                t.setState({ iosLink: response.iosAppUrl, room_title: response.room_name }, () => {
                    t.init_complete();


                });

            } else {

            }
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}

export function after_init(t) {

    update_admin_alive(t.state.room_id, t.state.user_guid);
    adminInterval = setInterval(function () { update_admin_alive(t.state.room_id, t.state.user_guid); }, 30000);//30 seconds

}

export function logout_room_admin(t) {

    var parameters = {
        room_guid: t.state.room_id,
    };

    $.ajax({
        url: '/api/dvmeet/room_logout_admin/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        async: true,
        contentType: 'application/json',
        dataType: "json",
        success: function (response) {
            console.log(response);
            if (response !== null && response !== undefined && response === true) {

            }
            clearInterval(adminInterval);
            t.redirect_home();
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}


export function ExiteUser(t) {
    clearInterval(myInterval);
    t.redirect_home();
}

export function check_admin_exist(t) {
    var parameters = {
        room_guid: t.state.room_id,
    };

    $.ajax({
        url: '/api/dvmeet/check_admin_exist/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        contentType: 'application/json',
        dataType: "json",
        async: true,
        success: function (response) {
            console.log(response);
            if (response === true) {
                clearInterval(myInterval);

                if (!t.state.meet_room_pass) {
                    t.redirect_meetroom();
                } else {

                    t.setState({ show_password: true }, () => {
                        t.showpassword();
                    });
                }
            } 
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}

export function check_password(t) {

    var parameters = {
        room_password: t.state.meet_room_pass,
        upassword: t.state.upassword
    };

    $.ajax({
        url: '/api/dvmeet/check_room_password/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        contentType: 'application/json',
        dataType: "json",
        async: true,
        success: function (response) {
            console.log(response);
            if (response === true) {
                t.redirect_meetroom();
            }
            else {
                //show password is wrong
                t.setState({ AlertMessage: 'Oops, Check room password and try again', AlertType: 'error' }, () => {
                    t.updateAlertMessage(t.state.AlertMessage,t.state.AlertType);
                });
            }
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}


export function check_room_exist(t,pagetype) {

    var parameters = {
        room_guid: t.state.room_id,
        user_first_last_name: t.state.user_flname
    };

    $.ajax({
        url: '/api/dvmeet/check_room_exist/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        contentType: 'application/json',
        dataType: "json",
        async: true,
        success: function (response) {
            console.log(response);
            if (!response) {

                t.redirect_home();
            } else {
                if (pagetype === 'waiting') {

                    t.setState({ meet_room_pass: response.room_password, room_title: response.room_name}, () => {

                        if (response.room_allow_guests === true) {

                            if (!t.state.meet_room_pass) {
                                t.redirect_meetroom();
                            } else {

                                t.setState({ show_password: true }, () => {
                                    t.showpassword();
                                });
                            }

                        } else {
                            myInterval = setInterval(function () { check_admin_exist(t); }, 5000);
                        }
                    });

                } else if (pagetype === 'room') {

                    t.setState({ domain: response.meet_domain,room_title: response.room_name,iosLink: response.iosAppUrl  }, () => {
                        t.init_complete();

                      myInterval =  setInterval(function () { check_admin_alive(t.state.room_id); }, 60000);

                    });
                       
                }

            }
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });

}


function update_admin_alive(_room_guid,_user_guid) {
        var parameters = {
            room_guid: _room_guid,
            user_guid: _user_guid
    };

    $.ajax({
        url: '/api/dvmeet/update_admin_alive/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        async: true,
        contentType: 'application/json',
        dataType: "json",
        success: function (response) {
            console.log(response);
            if (response !== null && response !== undefined && response === "true") {

            }
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}

function check_admin_alive(_room_guid) {
        var parameters = {
            room_guid: _room_guid
    };

    $.ajax({
        url: '/api/dvmeet/check_admin_alive/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        async: true,
        contentType: 'application/json',
        dataType: "json",
        success: function (response) {
            console.log(response);
            if (response !== null && response !== undefined && response === false) {
                window.location.href = "https://meet.discovervideo.com";
            }
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}
