import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Rooms } from './components/Rooms';
import { JitsiMeetComponent } from './components/JitsiMeetComponent';
import { WaitingRoom } from './components/WaitingRoom';
import { WebRecorder } from './components/WebRecorder';
import './custom.css'


export default class App extends Component {
    static displayName = App.name;


    constructor(props) {
        super(props);


        this.state = {
            showComponent: false,
        };

    }
    componentDidMount() {

        var pathname = '';

        if (window.location) {
            pathname = window.location.pathname;
        }

        if (pathname !== "/Meetroom") {
            this.setState({ showComponent: false });
        }
    }

    render() {
        return (

            <Layout showComponent={this.state.showComponent} >

                <Route exact path='/' component={Home} />
                <Route path='/Rooms' component={Rooms} />
                <Route path='/Meetroom' component={JitsiMeetComponent} />
                <Route path='/WaitingRoom' component={WaitingRoom} />
                <Route path='/WebCamRecorder' component={WebRecorder} />

            </Layout>

        );
    }
}
