import React, { Component } from 'react';
import * as util from '../WebRecorder/js/WebUtil';
import { Button, Row,Col } from 'reactstrap';



export class WebRecorder extends Component {
    static displayName = WebRecorder.name;
    constructor(props) {
        super(props);

        this.state = {
            showComponent: true,
            showExtDownload: false,
            showDownload: false,
            recorder: undefined,
            srcUrl: '',
            disableStartBtn: false,
            disableStopBtn: true,
            showMsg: true
        };

        window.WebRecorder = this;
        this.clicklink = this.clicklink.bind(this);
        this.close_msg = this.close_msg.bind(this);
    }

    componentDidMount() {


        if (typeof window.RecordRTC_Extension === 'undefined') {
            this.setState({ showExtDownload: true, showMsg: true });
        } else {

            this.setState({ recorder: new window.RecordRTC_Extension() });
        }
    }
    static getDerivedStateFromProps(props, state) {

    }

    start_recording = (event) => {
        this.setState({ disableStartBtn: true, disableStopBtn: false, showDownload: false });
        var options = this.state.recorder.getSupoortedFormats()["Screen + Microphone + Speakers"];
        this.state.recorder.startRecording(options, function (e) {
            //disable start button
           
        });


    }

    stop_recording = (event) => {
        this.setState({ disableStartBtn: false, disableStopBtn:true, showDownload: true});
        util.stop_now(this);
    }

    cancel_recording() {
         this.setState({ disableStartBtn: false, disableStopBtn:true, showDownload: false});
    }

    url_callback(url) {
        this.setState({ srcUrl: url,recorder: new window.RecordRTC_Extension() });
    }

    clicklink() {
        window.open('https://chrome.google.com/webstore/detail/recordrtc/ndcljioonkecdnaaihodjgiliohngojp?hl=en', '_blank');
    }

    close_msg() {
        this.setState({ showMsg: false });
    }

    render() {
        return (
            <div style={{ minWidth: '500px' }}>
                {this.state.showComponent ?
                    <div>
                        <div>
                            {this.state.showExtDownload ?
                                <Row>
                                    {this.state.showMsg ?
                                        <Col>
                                            <p>Chrome extension is either disabled or not installed. Please enable the extention or Download &nbsp; <Button onClick={this.clicklink}>Click Here</Button>
                                        &nbsp;<Button onClick={this.close_msg}>Close</Button>
                                            </p>
                                        </Col>
                                        :
                                        null
                                    }
                                </Row>
                                :
                                <Row>
                                    
                                    <Col>
                                        <Button id="btn-start-recording" onClick={this.start_recording} disabled={this.state.disableStartBtn} > Start Recording</Button>
                                &nbsp;&nbsp;
                                <Button id="btn-stop-recording" onClick={this.stop_recording} disabled={this.state.disableStopBtn}>Stop Recording</Button>
                                &nbsp;&nbsp;

                                {this.state.showDownload ?
                                            <a href={this.state.srcUrl} download="meet_recorded.webm" target="_blank" rel="noopener noreferrer">
                                                <Button>
                                                    <i className="fas fa-download" />
                                           Download File
                                         </Button>
                                            </a>
                                            : null}
                                    </Col>
                                </Row>
                            }

                        </div>
                    </div >
                    : null}
            </div>
        );
    }
}
            

