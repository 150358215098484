import React from 'react';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';


export default class DialogContaincer extends React.Component {
   
 

    constructor(props) {


        super(props);
        this.state = {
            roomInEdit: this.props.dataItem || null,
  
        };

         this.roomNameBox = this.roomNameBox.bind(this);
    }
    handleSubmit(event) {


        event.preventDefault();
    }

    onDialogInputChange = (event) => {



        let target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.props ? target.props.name : target.name;

        const edited = this.state.roomInEdit;
        edited[name] = value;

        this.setState({
            roomInEdit: edited
        });
    }

    roomNameBox = ()=> {

        if (this.state.roomInEdit.room_type === "devos") {
          return (
                <label name="room_name" >
                    {this.state.roomInEdit.room_name || ''}
              </label>
            );
        } else {
            return(
                <input
                    type="text"
                    name="room_name"
                    value={this.state.roomInEdit.room_name || ''}
                    onChange={this.onDialogInputChange}
                />
            );
        }
    }

    render() {



      return (
        <Dialog
            onClose={this.props.cancel}
        >
              <form onSubmit={this.handleSubmit}>

                <div style={{ marginBottom: '1rem' }}>
                  <label>
                    Room ID<br />
                    <label name="room_guid">
                       {this.state.roomInEdit.room_guid || this.state.nroom_guid}
                    </label>
                  </label>
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <label>
                          Room Name<br />
                          {this.roomNameBox()} 
                    </label>
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <label>
                    Room Password<br />
                    <Input
                        type="text"
                        name="room_password"
                        value={this.state.roomInEdit.room_password || ''}
                        onChange={this.onDialogInputChange}
                    />
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="room_allow_guests"
                            checked={this.state.roomInEdit.room_allow_guests || false}
                            onChange={this.onDialogInputChange}
                        />
                        Allow Guests
                    </label>
                </div>
            </form>
            <DialogActionsBar>
                <button
                    className="k-button"
                    onClick={this.props.cancel}
                >
                    Cancel
                </button>
                <button
                    className="k-button k-primary"
                    onClick={this.props.save}
                >
                    Save
                </button>
            </DialogActionsBar>
        </Dialog>
    );
  }
}
