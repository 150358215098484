import $ from 'jquery';

export function get_user_all_rooms(t) {

    var parameters = {
        user_guid: t.state.user_guid,
        org_guid: t.state.org_guid,
    };
    $.ajax({
        url: '/api/dvmeet/get_all_rooms_by_user_orgs_guid/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        async: true,
        contentType: 'application/json',
        dataType: "json",
        success: function (response) {

            if (response) {
                t.setState({
                    data: response,
                });
            }
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}

export function saveDB(item, org_guid,t) {
    var parameters = {
        room_guid: item.room_guid,
        room_name: item.room_name,
        room_password: item.room_password,
        room_allow_guests: item.room_allow_guests,
        room_allow_record: item.room_allow_record,
        org_guid: org_guid,
        user_guid: t.state.user_guid,
        room_type:  item.room_type,
    };
    $.ajax({
        url: '/api/dvmeet/update_room_settings/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        async: true,
        contentType: 'application/json',
        dataType: "json",
        success: function (response) {

            if (response === true) {
                t.setState({ AlertMessage: 'Update Succefully', AlertType: 'info' }, () => {
                    t.updateAlertMessage(t.state.AlertMessage,t.state.AlertType);
                });
            } else {
                t.setState({ AlertMessage: 'Update Fail Contact Support', AlertType: 'error' }, () => {
                    t.updateAlertMessage(t.state.AlertMessage,t.state.AlertType);
                });
            }
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}

export function deleteDB(item) {
    var parameters = {
        room_guid: item.room_guid,
        org_guid: item.org_guid,
    };
    $.ajax({
        url: '/api/dvmeet/delete_room_settings/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        async: true,
        contentType: 'application/json',
        dataType: "json",
        success: function (response) {

        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}