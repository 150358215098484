import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, CardHeader, CardBody } from 'reactstrap';
import { Tabs, Tab } from 'react-bootstrap';
import * as qs from 'query-string';
import RoomsGrid from '../../src/components/settingsOps/RoomsGrid';
import cookie from 'react-cookies';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';

const LinkStyle = {
    color: '#fff',
    fontSize: '16px',
    cellPadding: '10'
};

export class Rooms extends Component {
  static displayName = Rooms.name;

    constructor(props) {
        super(props);

        var ui = cookie.load('dvmeet');

        if (ui === null || ui === undefined) {
           this.state = {
                key: "general",
                user_guid: '',
                org_guid: '',
                user_flname: ''
            };

        }else {
            this.state = {
                key: "general",
                user_guid: ui.toug,
                org_guid: ui.toog,
                user_flname: ui.tofl,
                room:''
            };
        }

    }


    componentDidMount() {

        if (window.location.search) {
            var _room_id = qs.parse(window.location.search, { ignoreQueryPrefix: true }).id;

            if (_room_id) {
                this.setState({ room: "Meetroom?id=" + _room_id });
            }
        }
        $("#recordingdiv").hide();
        $("#setting_link").hide();
        if (!this.state.user_guid) {

            this.props.history.push("/");
        }


    }
    navLinkShow() {
        if (this.state.room) {
            return (
                <NavLink tag={Link} style={LinkStyle}  to={this.state.room}>Back to Room</NavLink>
            );
        }
    }

    render() {



    return (
            <Container>
                <Card className="cardStyle">
                    <CardHeader>
                        <img className="logo" src="https://meet.discovervideo.com/images/watermark_dv.png" alt="" />
                    </CardHeader>
                <CardBody>
                    {this.navLinkShow()}
                    <Tabs
                        id="controlled-tab"
                        activeKey={this.state.key}
                        onSelect={(k) => this.setState({ key: k })}
                        >
                          <Tab eventKey="general" title="General Room Settings">
                             <RoomsGrid user_guid={this.state.user_guid} org_guid={this.state.org_guid}/>
                          </Tab>

                        </Tabs>
                    </CardBody>
                </Card>
            </Container>
    );
  }
}
