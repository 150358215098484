import React, { Component } from 'react';
import ProgressComponent from '@material-ui/core/CircularProgress';
import * as qs from 'query-string';
import { isIOS } from "react-device-detect";
import cookie from 'react-cookies';
import $ from 'jquery';

import * as JRoom from '../components/js/JRoom';

export class JitsiMeetComponent extends Component {
    static displayName = JitsiMeetComponent.name;

    constructor(props) {
        super(props);

        var ui = cookie.load('dvmeet');
        var _room_id = qs.parse(window.location.search, { ignoreQueryPrefix: true }).id;

        if (ui === null || ui === undefined) {

            this.state = {
                ui: undefined,
                room_id: _room_id,
                loading: true,
                setLoading: true,
            };
        }
        else {
            this.state = {
                id: '',
                ui: 'valid',
                user_guid: ui.toug,
                org_guid: ui.toog,
                user_flname: ui.tofl,
                user_type: ui.utype,
                loading: true,
                setLoading: true,
                domain: ui.domain,
                account_valid: ui.accvalid,
                meet_room: _room_id,
                room_id: _room_id,
                room_title: '',
                participants: [],
                iosLink: '',
                apple_app: 'https://apps.apple.com/us/app/dvmeet/id1518588589'
            };
        }
    }

    componentDidMount() {

        if (this.state.ui === null || this.state.ui === undefined) {
            if (this.state.room_id) {
                this.props.history.push("/?id=" + this.state.room_id);
            } else {
                this.props.history.push("/");
            }
        }
        else if (!this.state.room_id) {
            this.props.history.push("/");
        } else {
            if (this.state.user_type === "admin") {
               // this.props.changeRoomLink('Rooms?id=' + this.state.room_id);
                $("#recordingdiv").show();
                $("#setting_link").show();
                JRoom.check_add_admin(this);


            }
            else {
                $("#recordingdiv").hide();
                $("#setting_link").hide();
                JRoom.check_room_exist(this, 'room');
            }


        }
    }

    init_complete = () => {

        //Show IOS info
        if (isIOS) {
            //ios device

        } else {
            //if not IOS then 
            this.useEffect();

            if (this.state.user_type === "admin") {
                JRoom.after_init(this);
            }
        }
    }


    redirect_home = () => {
        if (this.state.room_id) {
            this.props.history.push("/?id=" + this.state.room_id);
        } else {
            this.props.history.push("/");
        }
    }

    redirect_waiting = () => {
        this.props.history.push("/WaitingRoom?id=" + this.state.room_id);
    }

    startConference = () => {
        try {
            const options = {
                roomName: this.state.meet_room,
                width: window.width,
                height: window.innerHeight - 68,
                parentNode: document.getElementById('jitsi-container'),
                interfaceConfigOverwrite: {
                    filmStripOnly: false,
                    SHOW_JITSI_WATERMARK: false,
                },
                configOverwrite: {
                    disableSimulcast: false,
                    disableDeepLinking: true
                },

            };

            const api = new window.JitsiMeetExternalAPI(this.state.domain, options);
            api.executeCommand('subject', this.state.room_title);

            api.addEventListener('videoConferenceJoined', (data) => {


                console.log('Local User Joined');
                if (data.displayName === this.state.user_flname) {
                    this.setState({ id: data.id });
                }

                this.setState({ setLoading: false, loading: false });
                api.executeCommand('displayName', this.state.user_flname);


            });

            api.addEventListener('videoConferenceLeft', (data) => {

                if (this.state.user_type === "admin") {
                    JRoom.logout_room_admin(this);
                } else {
                    JRoom.ExiteUser(this);
                }

            });

            api.addEventListener('participantJoined', (data) => {

                //if (this.state.user_type === "admin") {
                //    var ulist = this.state.participants.concat(data.id);
                //    this.setState({ participants: ulist });
                //}
                //else {

                //    if (data.displayName === this.state.user_flname) {
                //        this.setState({ id: data.id });
                //    }
                //}

            });

            api.addEventListener('participantLeft', (data) => {
                //if (this.state.id === data.id) {
                //    console.log('Local User left');
                //    if (this.state.user_type === "admin") {
                //        JRoom.logout_room_admin(this);
                //    }
                //    else {

                //        this.props.history.push("/");
                //    }
                //}  
            });



            api.addEventListener('participantKickedOut', (data) => {

            });

            api.on('readyToClose', () => {
                console.log('dispose');
                api.dispose();
            });
        } catch (error) {
            console.error('Failed to load Jitsi API', error);
        }
    }

    useEffect = () => {
        // verify the JitsiMeetExternalAPI constructor is added to the global..
        if (window.JitsiMeetExternalAPI) { this.startConference(); }
        else { alert('Jitsi Meet API script not loaded'); }
    };

    render() {

        const iosstyle = {
            display: 'flex', justifyContent: 'center'
        };
        const containerStyle = {
            width: '100%',
            height: '100%',

        };

        const jitsiContainerStyle = {
            display: (this.state.loading ? 'none' : 'block'),
            width: '100%',
            height: '100%',
        }

        if (isIOS) {
            return (
                <div style={containerStyle} >
                    <div style={iosstyle} >
                        <table>
                            <tr>
                                <tb>
                                    <a href={this.state.iosLink} style={{ paddingLeft: '0px' }}>
                                        <img border="0" alt="DV Meet App" src="icon.png" />
                                    </a>
                                    <br />
                                    <a style={{ color: 'white' }} href={this.state.iosLink} > Launch DV Meet App</a>
                                </tb>

                            </tr>
                            <tr>
                                <tb>
                                    <div><br /></div>
                                </tb>
                            </tr>
                            <tr>
                                <tb>

                                    <a href={this.state.apple_app} >
                                        <img border="0" alt="W3Schools" src="apple_app.svg" />
                                    </a>

                                </tb>
                            </tr>
                        </table>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={containerStyle} >
                    {this.state.loading && <ProgressComponent />}
                    <div id="jitsi-container" style={jitsiContainerStyle} />
                </div>
            );
        }

    };

}



