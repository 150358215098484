import React, { Component } from 'react';
import '@progress/kendo-theme-default/dist/all.css';
import { Grid, GridColumn as Column,GridToolbar } from '@progress/kendo-react-grid';
import {ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import * as RoomsJS from '../../components/settingsOps/js/RoomsGridJs.js';

import DialogContainer from '../settingsOps/DialogContaincer';
import cellWithEditing from '../settingsOps/cellWithEditing.js';


export default class RoomsGrid extends Component {

    constructor(props) {
        super(props);



        this.state = {
            data: [],
            user_guid: this.props.user_guid,
            org_guid: this.props.org_guid,
            editID: null,
            roomInEdit: undefined,
            AlertMessage: '',
            AlertType: 'info',
            visible: false,
            deleted_item: [],

        };

        this.removenow = this.removenow.bind(this);
        this.removecancel = this.removecancel.bind(this);
    }


    updateAlertMessage = (msg, AlertType) => {

        this.toastId = toast(msg, {
            transition: Slide,
            closeButton: true,
            autoClose: 5000,
            position: 'bottom-center',
            type: AlertType,
        });
    }

    save = () => {


        const dataItem = this.state.roomInEdit;
        const cdata = this.state.data.slice();
        const isNewItem = dataItem.room_id === undefined;

        if (isNewItem) {
              cdata.unshift(this.AddNewItem(dataItem));
        } else {
            const index = cdata.findIndex(p => p.room_id === dataItem.room_id);
            cdata.splice(index, 1, dataItem);
        }

        this.setState({
            data: cdata,
            roomInEdit: undefined
        }, () => {

             RoomsJS.saveDB(dataItem, this.state.org_guid, this);

        });
    }

    
    AddNewItem(source) {
        const id = this.state.data.reduce((item, current) => Math.max(item, current.room_id || 0), 0) + 1;
        const newItem = {
            room_id: id,
            org_guid: this.state.org_guid,
            user_guid: this.state.user_guid,
            room_guid: source.room_guid,
            room_name: source.room_name,
            room_password: source.room_password,
            room_allow_guests: source.room_allow_guests,
            room_allow_record: 'false',
            room_type: 'custom',
        };

        return Object.assign(newItem, source);
    }


    iniNewItem() {

        var ids = require('short-id');

        const newItem = {
            room_id: undefined,
            org_guid: this.state.org_guid,
            user_guid: this.state.user_guid,
            room_guid: ids.generate(),
            room_name: '',
            room_password: '',
            room_allow_guests: false,
            room_allow_record: false,
            room_type: 'custom',
        };

        return newItem;
    }

    componentDidMount() {
        this.getUserAllRooms();
    }

    getUserAllRooms = () => {
        RoomsJS.get_user_all_rooms(this);
    }

    dialogTitle() {
        return `${this.state.roomInEdit.room_id === undefined ? 'Add' : 'Edit'} item`;
    }
    cloneitem(item) {
        return Object.assign({}, item);
    }

    edit = (dataItem) => {
        this.setState({ roomInEdit: this.cloneitem(dataItem) });
    }

    insert = () => {

        this.setState({ roomInEdit: this.iniNewItem() });
    }

    cancel = () => {
        this.setState({ roomInEdit: undefined });
    }
    remove = (dataItem) => {

        this.setState({
            deleted_item: dataItem, visible: true
        });
    }

    removenow = () => {

        RoomsJS.deleteDB(this.state.deleted_item);

        this.setState({
            data: this.state.data.filter(p => p.room_guid !== this.state.deleted_item.room_guid)
        }, () => {
                this.setState({
                    deleted_item: undefined, visible: false
                });
        });
    }

    removecancel = () => {
        this.setState({
            deleted_item: undefined, visible: false
        });
        
    }

    render() {

       
        return (
          <div >
            <Grid
                  data={this.state.data}
                    style={{ height: '420px' }}
            >
               <GridToolbar>
                <button onClick={this.insert} className="k-button">
                    Add New
                </button>
                </GridToolbar>
                <Column field="room_id" title="Id" width="50px" editable={false} />
                <Column field="room_guid" title="Room ID" editable={false} />
                <Column 
                   field="room_name" 
                   title="Name" 
                   editable={false}
                   cell={ (props) => 
                       <td>
                           <a href={'/Meetroom?id=' + props.dataItem.room_guid} style={{ color: 'blue' }}>
                            { props.dataItem.room_name}
                         </a>
                       </td>
                       }
                    /> 
                <Column field="room_password" title="Password" />
                <Column field="room_allow_guests" title="Allow Guests" editor="boolean" />
                  <Column
                        title="Edit"
                        cell={cellWithEditing(this.edit, this.remove)}
                    />

            </Grid>
                {this.state.roomInEdit && <DialogContainer dataItem={this.state.roomInEdit} save={this.save} cancel={this.cancel} />}

                {this.state.visible && <Dialog title={"Please confirm"}>
                    <p style={{ margin: "25px", textAlign: "center" }}>Confirm Delete Room {this.state.deleted_item.room_name}?</p>
                    <DialogActionsBar>
                        <button type="submit" className="k-button" onClick={() => this.removecancel()}>No</button>
                        <button type="submit" className="k-button" onClick={()=>this.removenow()}>Yes</button>
                    </DialogActionsBar>
                </Dialog>}

                <ToastContainer />
         </div>
        );
    }

}