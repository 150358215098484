import $ from 'jquery';


export function get_user_info(t) {
    //TODO: validation username and passowrd is here
    var parameters = { userName: t.state.userName, userPass: t.state.userPass};

    $.ajax({
        url: '/api/dvmeet/login_cloud_account/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        async: true,
        contentType: 'application/json',
        dataType: "json",
        success: function (response) {
            //set the state from the response
            console.log(response);

            if (response !== null && response !== undefined) {
                var data = {
                    tuser: t.state.userName,
                    toug: response.userGuid,
                    toog: response.userOrg,
                    tofl: response.userFullname,
                    utype: 'admin',
                    domain: response.meet_domain,
                    accvalid: response.org_valid
                }

                t.setState({ ui: data }, () => {
                    t.LoginAthenticateComplete();

                });
               
            } else {
                t.setState({ AlertMessage: 'Oops, Check your username or password.', AlertType: 'error' }, () => {
                    t.updateAlertMessage(t.state.AlertMessage,t.state.AlertType);
                });
            }
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });
}

export function check_room_exist(t) {

    var parameters = {
        room_guid: t.state.room_id,
    };

    $.ajax({
        url: '/api/dvmeet/check_room_exist/data',
        type: 'POST',
        data: JSON.stringify(parameters),
        contentType: 'application/json',
        dataType: "json",
        async: true,
        success: function (response) {
            console.log(response);
            if (response) {

                t.redirect_waiting();
            } else {
                t.setState({ AlertMessage: 'Oops, Room ID not found.', AlertType: 'error' }, () => {
                    t.updateAlertMessage(t.state.AlertMessage,t.state.AlertType);
                });
            }
        },
        error: function (e, a, t) {
            console.log(e);
        }
    });

}