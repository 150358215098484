import React, { Component } from 'react';
import { Form,FormGroup,Label,Input,Button,Container,Row,Col,Card,CardHeader, CardBody } from 'reactstrap';
import {ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import *  as loginutils from './js/login.js';
import * as qs from 'query-string';
import cookie from 'react-cookies';
import CookieConsent from "react-cookie-consent";
import $ from 'jquery';

export class Home extends Component {
    static displayName = Home.name;
     constructor(props) {
        super(props);

       var _room_id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id;

         var ui = cookie.load('dvmeet');
         var vuser = '';

         if (ui !== null && ui !== undefined) {
             vuser = ui.tuser;
         }

        this.state = {
            ui: [],
            userName: vuser,
            userPass:'',
            remeberCheck: false,
            AlertMessage: '',
            AlertType: 'info',
            room_id: _room_id !== null ? _room_id : '',
            vflname: ''
        };

        this.handle_visitor_login_click = this.handle_visitor_login_click.bind(this);
        this.handle_login_click = this.handle_login_click.bind(this);
        this.LoginAthenticateComplete = this.LoginAthenticateComplete.bind(this);
    }

    componentDidMount() {

        $("#recordingdiv").hide();
        cookie.remove('dvmeet', { path: '/' });
        $("#setting_link").hide();
        if (this.state.vuser) {

        }
    }


    LoginAthenticateComplete() {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        cookie.save('dvmeet', this.state.ui, { path: '/', expires: tomorrow })

        if (this.state.room_id) {

            this.props.history.push("/MeetRoom?id=" + this.state.room_id);
        } else {
            //if there is a room requested in the header if not goto settings
            this.props.history.push("/Rooms");
        }
    }


    handle_visitor_login_click = (event) => {
        loginutils.check_room_exist(this);
    }
    


    redirect_waiting = () => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        var data = {
            toug: '',
            toog: '',
            tofl: this.state.vflname,
            utype: 'user',
            domain: '',
            accvalid: 'false'
        };

        cookie.save('dvmeet', data, { path: '/', expires: tomorrow })

        if (!this.state.room_id) {
             this.setState({ AlertMessage: 'Enter Room ID', AlertType: 'error' }, () => {
                    this.updateAlertMessage(this.state.AlertMessage,this.state.AlertType);
            });

        }
        else if(!this.state.vflname) {
                this.setState({ AlertMessage: 'Enter Your Name', AlertType: 'error' }, () => {
                    this.updateAlertMessage(this.state.AlertMessage,this.state.AlertType);
                });
        } else {

            this.props.history.push("/WaitingRoom?id=" + this.state.room_id);
        }

    }
    handle_login_click = () => {
        loginutils.get_user_info(this);
    }


    handle_login_enter = (event) => {
        if (event.key === 'Enter') {
            this.handle_login_click();
        }
    }

    updateAlertMessage = (msg, AlertType) => {

        this.toastId = toast(msg, {
            transition: Slide,
            closeButton: true,
            autoClose: 5000,
            position: 'bottom-center',
            type: AlertType,
        });
    }


    render() {
      
        return (
            <Container>
                <Card className="cardStyle">
                    <CardHeader>
                        <img className="logo" src="https://meet.discovervideo.com/images/watermark_dv.png" alt="" />
                        <p style={{ marginLeft: '40px', fontSize: '12px' }}>Version: Beta 2</p>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <Form>
                                    <FormGroup>
                                        <h4>Visitor Access</h4>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="Roombox">Room ID</Label>
                                        <Input type="text" name="Room" id="Roombox" placeholder="Enter Room ID" value={this.state.room_id} onChange={(e) => { this.setState({ room_id: e.target.value }); }} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="Namebox">Name</Label>
                                        <Input type="text" name="Name" id="Namebox"  value={this.state.vflname} onChange={(e) => { this.setState({ vflname: e.target.value }); }}  placeholder="Enter Your Name" />
                                    </FormGroup>
                                    <Button onClick={this.handle_visitor_login_click}>>Join Now</Button>
                                    <br /><br />
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <FormGroup>
                                        <h4>Admin Access</h4>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="usernamebox">Username/Email</Label>
                                        <Input type="text" name="username_email" id="usernamebox"  value={this.state.userName} onChange={(e) => { this.setState({ userName: e.target.value }); }} placeholder="Enter Username/Email" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="Namebox">Access Code</Label>
                                        <Input type="password" name="Password" id="passwordbox" value={this.state.userPass} onChange={(e) => { this.setState({ userPass: e.target.value }); }} placeholder="Enter Your Access Code" />
                                    </FormGroup>
                                    <Button onClick={this.handle_login_click}>Sign In</Button>
                                </Form>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
                <ToastContainer />
                <CookieConsent
                  location="bottom"
                  buttonText="Accept Cookies"
                  cookieName="DVCookiesAccept"
                  style={{ background: "#2B373B" }}
                  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                  expires={150}
                >
                  We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners.
                </CookieConsent>
            </Container>
        );
    }
}
