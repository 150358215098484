import React, { Component } from 'react';
import { NavMenu } from './NavMenu';


export class Layout extends Component {
  static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            showDownload: false
        };

    }
    render() {
        const containerStyle = {
            width: '100%',
            height: '100%',
        };

    return (
        <div>
            <NavMenu showDownload={this.state.showDownload} />
        <div style={containerStyle}>
              {this.props.children} 
        </div>
      </div>
    );
  }
}
