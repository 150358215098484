import React, { Component } from 'react';
import '@progress/kendo-theme-default/dist/all.css';
import {Button, Input, Container, Card, CardHeader, CardBody } from 'reactstrap';
import {ToastContainer, toast, Slide } from 'react-toastify';
import cookie from 'react-cookies';
import * as qs from 'query-string';
import * as JRoom from '../components/js/JRoom';
import { lime } from '@material-ui/core/colors';
import $ from 'jquery';

export class WaitingRoom extends Component {
  static displayName = WaitingRoom.name;

    constructor(props) {
        super(props);

        var ui = cookie.load('dvmeet');
        var _room_id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id;

        if (ui === null || ui === undefined) {
            this.props.history.push("/");
        }
        else {
            this.state = {
                user_guid: ui.toug !== null ? ui.toug : '',
                org_guid: ui.toog !== null ? ui.toog : '',
                user_flname: ui.tofl !== null ? ui.tofl : '',
                user_type: ui.utype !== null ? ui.utype : 'user',
                domain: ui.domain !== null ? ui.domain : '',
                account_valid: ui.accvalid !== null ? ui.accvali : 'false',
                meet_room: _room_id,
                room_id: _room_id,
                meet_room_pass: undefined,
                show_password: false,
                upassword: '',
                AlertMessage: '',
                AlertType: 'info',
            };
        }
    }


    componentDidMount() {

        $("#setting_link").hide();

        if (!this.state.room_id) {

            this.props.history.push("/");
        }
        else {
            JRoom.check_room_exist(this,'waiting');
        }

    }

    updateAlertMessage = (msg, AlertType) => {

        this.toastId = toast(msg, {
            transition: Slide,
            closeButton: true,
            autoClose: 5000,
            position: 'bottom-center',
            type: AlertType,
        });
    }

    redirect_meetroom = () => {
        this.props.history.push("/MeetRoom?id=" + this.state.room_id);
    }

    redirect_home = () => {
        this.props.history.push("/");
    }

    handle_visitor_login_click = (event) => {

        if (this.state.meet_room_pass) {
            JRoom.check_password(this);
        } else {

            if (this.state.room_id) {
                 this.props.history.push("/?id=" + this.state.room_id);
            }
            else {
                this.props.history.push("/");
            }
        }
    }
    
    showpassword = () => {
        if (this.state.show_password === false) {
            return (
                 <div>
                   <h4> Waiting for Room Admin</h4>
                   <h6>Please wait till we redirect</h6>
                   <span className="k-icon k-i-loading" style={{ color: lime }}></span>
                 </div>
                );
        } else {
            return (
                <div>
                    <h4> Waiting for You</h4>
                    <h6>Enter Room Password</h6>
                    <Input type="password" name="Password" id="passwordbox" style={{width:200}}
                        value={this.state.upassword} onChange={(e) => { this.setState({ upassword: e.target.value }); }} placeholder="Enter Room Password" /><br />
                     <Button onClick={this.handle_visitor_login_click}>Enter Room</Button>
                </div>
            );
        }
    }

  render() {
    return (
            <Container>
                <Card className="cardStyle">
                    <CardHeader>
                        <img className="logo" src="https://meet.discovervideo.com/images/watermark_dv.png" alt="" />
                    </CardHeader>
                    <CardBody>
                    { this.showpassword() }
                    </CardBody>
            </Card>
            <ToastContainer />
            </Container>
    );
  }
}
