
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function cellWithEditing(edit, remove) {
    return class extends GridCell {

        remove_section() {

            if (this.props.dataItem.room_type === "custom") {
                return (
                    <button
                        className="k-button k-grid-remove-command"
                        onClick={() => {
                             remove(this.props.dataItem);
                        }}
                    >
                        Remove
                    </button >
                );
            }
        }

        render() {



            return (
                <div>
                    <button
                        className="k-primary k-button k-grid-edit-command"
                        onClick={() => { edit(this.props.dataItem); }}
                    >
                        Edit
                    </button>                   
                    &nbsp;
                    {this.remove_section()}
                </div>
            );
        }
    };
}
