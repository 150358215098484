



export function stop_now(t) {

    t.state.recorder.stopRecording(stopRecordingCallback);

}

export function stopRecordingCallback(blob) {

    let url = window.URL.createObjectURL(blob);
    window.WebRecorder.url_callback(url);

}
