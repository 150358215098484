import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as qs from 'query-string';
import cookie from 'react-cookies';
import './NavMenu.css';
import { WebRecorder } from '../components/WebRecorder/index';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);


        var ui = cookie.load('dvmeet');
        var utype = "user";
        if (ui !== null & ui !== undefined) {
            utype = ui.utype;
        }

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            room: this.props.room,
            showComponent: this.props.showComponent === true ? this.probs.showComponent : false,
            user_type: utype,
        };

    }

    componentDidMount() {

        if (this.state.user_type === "admin") {
            this.setState({ showComponent: true }, () => {
                if (window.location.search) {
                    var _room_id = qs.parse(window.location.search, { ignoreQueryPrefix: true }).id;

                    if (_room_id) {
                        this.setState({ room: "Rooms?id=" + _room_id });
                    }
                }
            });
        }
        else {
            if (window.location.search) {
                var _room_id = qs.parse(window.location.search, { ignoreQueryPrefix: true }).id;

                if (_room_id) {
                    this.setState({ room: "Rooms?id=" + _room_id });
                }
            }
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }



    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3" dark>
                    <Container >
                        <div id="recordingdiv">
                            <WebRecorder showComponent={this.state.showComponent} />
                        </div>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>

                            <ul className="navbar-nav flex-grow">

                                <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink id="setting_link" tag={Link} className="text-light" to={this.state.room}> My Rooms</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>

                    </Container>
                </Navbar>
            </header>
        );
    }
}
